import React, { useState } from "react"
import styled, { css, createGlobalStyle } from "styled-components"
import { colors, bp, fonts, layout } from "../components/Styles"
import { Swiper, SwiperSlide } from "swiper/react"
import { SlideButtonInner } from "../components/SlideButton"
import Footer from "../components/Footer"
import { graphql } from "gatsby"
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/pagination"
import "swiper/css/navigation"
import BackgroundImage from "gatsby-background-image"
import { asBg } from "../util/image"
import Div100vh from "react-div-100vh"
import FadeInSection from "../util/fade-in-section"
import { useTranslation } from "gatsby-plugin-react-i18next"

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

const SlideContainer = styled(BackgroundImage)`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`

const Slides = styled.div`
  position: relative;
  height: calc(100% - 7.8rem);

  ${bp("mobile")`
    height:100%;
  `}
  .mySwiper {
    width: 100%;
    height: 100%;
  }

  .swiper-wrapper .mySwiperSlide {
    background: ${colors.backgroundDarker};
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    img {
      object-position: left;
    }
  }

  .swiper-pagination {
    text-align: start;
    width: ${layout.width};
    max-width: ${layout.maxWidth};
    bottom: 5rem;
    right: 0;
    margin: 0 auto;

    ${bp("mobile")`
      max-width: calc(100% - 10rem);
      text-align: center;
      display: flex;
      justify-content: space-between;
      bottom: 4em;
    `}

    > span {
      border: 3px solid ${colors.primary};
      width: 1.6rem;
      height: 1.6rem;
      background: transparent;
      opacity: 1;
      margin-left: 0 !important;
      margin-right: 5rem !important;
      ${bp("mobile")`
      margin-right: 0 !important;
  `}
    }
    .swiper-pagination-bullet-active {
      border-color: transparent;
      background: ${colors.white};
    }
  }
  .swiperFooter .swiper-pagination {
    text-align: end;
    width: auto;
    max-width: fit-content;
    bottom: auto;
    right: 0;
    margin: auto;

    > .swiper-pagination-bullet-active {
      background: ${colors.blue};
    }

    ${bp("mobile")`
        margin-right: 1rem;
        display: block;
    `}
  }
  .footerSlide {
    background: ${colors.white};
  }
`
const Title = styled.h3`
  font-size: calc(25vh - 8.625rem);
  ${bp("portrait")`
  font-size: calc(22vw - 14rem);
  `}
  text-transform: uppercase;
  &.slidePrimaryColor {
    color: ${colors.slidePrimary};
    > span {
      color: ${colors.slideBlue};
    }
  }
  &.slideSecondryColor {
    color: ${colors.slideSecondry};
  }
  &.slideBlueColor {
    color: ${colors.slideBlue};
    > span {
      color: ${colors.slidePrimary};
    }
  }
  ${bp("mobile")`
  font-size: calc(12vw - 1em);
`}
  ${bp("hdpi")`
  font-size: 13.5rem;
`}
`
const SlidOverlay = styled.div<{
  isShown?: boolean
}>`
  width: ${layout.width};
  max-width: ${layout.maxWidth};
  margin: 0 auto;
  position: absolute;
  top: 6rem;
  left: 0;
  right: 0;
  ${bp("mobile")`
    padding-top: 9em;
    max-width: ${layout.maxWidthMobile};
    `}
`

const Text = styled.p`
  width: 79rem;
  font-size: 1.688rem;
  color: #fff;
  position: relative;
  top: 2rem;
  ${bp("mobile")`
    width: 100%;
    `}
`
const Start: React.FC<{ data: any }> = ({ data }) => {
  const { innovativeSlide, mailSlide } = data

  const [pagination, setPagination] = useState(true)

  const { t } = useTranslation()
  const [isActive, setActive] = useState(false)
  return (
    <Div100vh>
      <GlobalStyle />
      <Slides>
        <Swiper
          direction={"horizontal"}
          passiveListeners={false}
          touchReleaseOnEdges
          pagination={pagination}
          mousewheel={true}
          className="mySwiper"
          effect="creative"
          creativeEffect={{
            prev: {
              shadow: true,
              translate: ["-20%", 0, -1],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
        >
          <SwiperSlide className="mySwiperSlide">
            <SlideContainer {...asBg(innovativeSlide)}>
              <SlidOverlay>
                <FadeInSection speed={0.5}>
                  <Title className="slidePrimaryColor">
                    {t("Innovative Software")}
                  </Title>
                  <Title className="slideSecondryColor">
                    {t("Fördert Wachstum.")}
                    <br />
                    {t("Schafft Lösungen.")}
                    <br />
                    {t("Macht zukunftssicher.")}
                  </Title>
                </FadeInSection>
              </SlidOverlay>
            </SlideContainer>
          </SwiperSlide>
          <SwiperSlide className="mySwiperSlide">
            <SlidOverlay>
              <FadeInSection speed={0.5}>
                <Title className="slidePrimaryColor">
                  {t("Digitalisierung")}
                </Title>
                <Title className="slideBlueColor">
                  {t("Wartet auf kein")}
                  <br />
                  {t("Unternehmen")}
                </Title>
                <Text>
                  {t(
                    "Von den 30 Dax-Gründungsunternehmen hat sich nur die Hälfte bis heute gehalten. Weiterhin waren 33 Unternehmen nicht in der Lage, gemeinsam mit den Anforderungen ihrer Kunden zu wachsen."
                  )}
                </Text>
              </FadeInSection>
            </SlidOverlay>
          </SwiperSlide>
          <SwiperSlide className="mySwiperSlide">
            <SlidOverlay>
              <FadeInSection speed={0.5}>
                <Title className="slideBlueColor">
                  {t("Was ist")} <span>{t("die richtige")}</span>
                </Title>
                <Title className="slidePrimaryColor">{t("Technologie?")}</Title>
                <Text>
                  {t(
                    "Digitale Technologie kann komplex sein und es ist schwierig die richtige Wahl zu treffen und die besten Möglichkeiten zu nutzen. Angesichts der rasanten Entwicklung und den sich ändernden Kundenbedürfnissen, gibt es kaum ein Unternehmen, das seine Produkte und Dienstleistungen nicht den digitalen Anforderungen anpassen muss."
                  )}
                </Text>
              </FadeInSection>
            </SlidOverlay>
          </SwiperSlide>
          <SwiperSlide className="mySwiperSlide">
            <SlidOverlay>
              <FadeInSection speed={0.5}>
                <Title className="slideBlueColor">
                  {t("Individuelle")}
                  <br />
                  {t("Herausforderungen")}
                </Title>
                <Title className="slidePrimaryColor">
                  {t("Gemeinsam lösen")}
                </Title>
                <Text>
                  {t(
                    "Der Dialog mit unseren Kunden ermöglicht es uns, neue Lösungsansätze zu entwickeln: Ihre Expertise bildet die Basis der digitalen Transformation. Ihre Anforderungen lenken die Konzeption, Entwicklung und Bereitstellung von Technologien. Dabei liegt für uns der Fokus auf einer kosteneffektiven Skalierung, um Ihr Unternehmenswachstum nachhaltig zu fördern."
                  )}
                </Text>
              </FadeInSection>
            </SlidOverlay>
          </SwiperSlide>
          <SwiperSlide className="mySwiperSlide">
            <Swiper
              direction={"vertical"}
              touchReleaseOnEdges
              pagination={false}
              mousewheel={{ forceToAxis: false }}
              nested={true}
              className="mySwiper swiperFooter"
              effect="fade"
              fadeEffect={{
                crossFade: true,
              }}
              onActiveIndexChange={i => setPagination(!pagination)}
            >
              <SwiperSlide>
                <SlideContainer {...asBg(mailSlide)}>
                  <SlidOverlay>
                    <FadeInSection speed={0.5}>
                      <Title className="slideSecondryColor">
                        {t("Jetzt ist die Zeit")}
                        <br />
                        {t("für eine")}
                      </Title>
                      <Title className="slidePrimaryColor">
                        {t("Digitale Lösung")}
                      </Title>
                      <SlideButtonInner />
                    </FadeInSection>
                  </SlidOverlay>
                </SlideContainer>
              </SwiperSlide>
              <SwiperSlide className="footerSlide">
                <Footer />
              </SwiperSlide>
            </Swiper>
          </SwiperSlide>
        </Swiper>
      </Slides>
    </Div100vh>
  )
}

export default Start

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    innovativeSlide: file(relativePath: { eq: "slides/Slide_1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, height: 1080)
      }
    }

    mailSlide: file(relativePath: { eq: "slides/Slide_5.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, height: 1080)
      }
    }
  }
`
