import React, { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
import { colors, fonts } from "./Styles"
import MailIcon from "../images/icons/Icn_Mail.svg"
import Modal from "./Modal"
import ContactForm, { ThanksModal } from "./modals/ContactForm"
import useIntersectionObserver from "../util/use-intersection-observer"
import { useTranslation } from "gatsby-plugin-react-i18next"

const SlideButton: React.FC<{
  href?: string
  shouldAppear?: boolean
  onClick?: (e: React.MouseEvent<any, MouseEvent>) => void
  className?: string
}> = ({ onClick, href, className, children }) => {
  const { t } = useTranslation()
  return (
    <>
      <Button onClick={e => onClick?.(e)} className={className}>
        <span>{t("Jetzt anfragen")}</span>
        <div className="addon">
          <MailIcon />
        </div>
      </Button>
    </>
  )
}

export const SlideButtonInner: React.FC<{ hideExtras?: boolean }> = ({
  hideExtras = false,
}) => {
  const [showModal, setShowModal] = useState(false)
  const fromElement = useRef<HTMLElement | null>(null)
  const [showCTA, setShowCTA] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const entry = useIntersectionObserver(containerRef, {
    freezeOnceVisible: true,
    threshold: 0.9,
  })

  useEffect(() => {
    if (!entry?.isIntersecting) return
    setShowCTA(true)
  }, [entry])

  const [wasSent, setWasSent] = useState(false)

  return (
    <SlideButtonInnerContainer {...{ hideExtras, showCTA }} ref={containerRef}>
      <SlideButton
        onClick={e => {
          fromElement.current = e.target as HTMLElement
          setShowModal(true)
        }}
        className={`${showModal ? "animate" : ""}`}
      ></SlideButton>

      <Modal
        onClose={() => setShowModal(false)}
        isShown={showModal}
        showBackdrop
        fromElement={fromElement}
      >
        <ContactForm
          onClose={() => setShowModal(false)}
          onSent={() => {
            setShowModal(false)
            setWasSent(true)
          }}
        />
      </Modal>

      <ThanksModal isShown={wasSent} onClose={() => setWasSent(false)} />
    </SlideButtonInnerContainer>
  )
}

const SlideButtonInnerContainer = styled.div`
  //
`

const Button = styled.a`
  font-family: ${fonts.bold};
  display: flex;
  justify-items: space-between;
  align-items: stretch;
  margin-top: 2em;
  background-color: ${colors.primary};
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  width: fit-content;
  font-size: 1.625rem;

  span {
    padding: 1.2em 2em 1.1em 1.2em;
    width: max-content;
    color: #000000;
  }

  div {
    padding-right: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 0.3s;
  }

  :hover div,
  &.animate div {
    // transform: translateX(74px)
    margin-left: 100vw;
  }

  svg {
    width: 1.5em;
  }
`
export default SlideButton
